const state = {
  acceptRegulations: true,
  agencyList: [],
  activityReport: [],
  agencyActivityReport: [],
  agentCellphone: null,
  applicationList: [],
  applicationsLoading: false,
  bankCode: null,
  bankList: [],
  consents: [],
  consentGroups: [],
  consentList: [],
  commissionEligible: false,
  criticalErrorModal: {
    message: "",
    show: false
  },
  credit: {
    pinApp: "",
    pinCon: "",
    confirmation: false,
    processId: null,
    policyId: null,
    accountNumber: null,
    insurer: null,
    isBusinessAvailable: false,
    clientType: "PRIVATE",
    companyData: {
      name: null,
      nip: null,
      city: null,
      street: null,
      house: null,
      apartment: null,
      zipCode: null,
    },
    clientData: {
      firstName: null,
      lastName: null,
      pesel: null,
      cellphone: null,
      email: null,
      differentCorrespondence: false,
      birthCountry: "PL",
      mainAddress: {
        city: null,
        street: null,
        house: null,
        apartment: null,
        zipCode: null,
      },
      correspondenceAddress: {
        city: null,
        street: null,
        house: null,
        apartment: null,
        zipCode: null,
      },
      identityDocumentData: {
        id: "",
        expiryDate: null,
        issueDate: null,
        indefinite: false
      },
      monthlyIncome: null
    },
    loanItem: {
      insuranceType: "VEHICLE",
      amount: null,
      vehicle: {
        make: null,
        model: null,
        licensePlate: null
      },
      property: {
        propertyType: "FLAT",
        city: null,
        street: null,
        house: null,
        apartment: null,
        zipCode: null,
      }
    }
  },
  currentStep: null,
  decisionData: {
    decision: null,
    decisionParameters: null,
    rejectionReason: null
  },
  disclaimer: "",
  documentList: [],
  errorModal: {
    messages: [],
    show: false
  },
  noLoanModal: {
    messages: [],
    show: false
  },
  formHasErrors: false,
  loginError: {
    password: null,
    email: null
  },
  showDisabledUserModal: false,
  loading: {
    message: "",
    show: false
  },
  isTrasti: false,
  newUser: {
    id: null,
    firstName:"",
    lastName:"",
    email: "",
    cellphone:"",
    pesel: "",
    salesmanId:"",
    agencyId:"",
  },
  newAgency: {
    id: null,
    name:"",
    code:"",
    productCode: "",
    isBusinessAvailable:false,
    isEmailRequired:false,
    baseUrl: "https://hipro12.pl",
  },
  partner: null,
  enableCompanyDetails: false,
  showCompanyDetails: false,
  showCreateUserModal: false,
  showCreateAgencyModal: false,
  showOkModal: false,
  validationModal: {
    show: false,
    messages: []
  },
  showConsentModal: false,
  showSessionEndModal: false,
  showContestModal: false,
  showUserModal: false,
  user: {
    firstName: "",
    lastName: "",
    email: "",
    cellphone: "",
    pesel: "",
    emailRequired: false,
    notification: false
  },
  userEmail: "",
  users: [],
  usersLoading: false
};

export default state
