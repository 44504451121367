<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-card-title class="headline">
        Przepraszamy
      </v-card-title>
      <v-card-text>
        <template v-for="message in messages">{{message}}<br></template>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="dialog = false">
          Zamknij
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "NoLoanModal",
    computed: {
      dialog: {
        get() {
          return this.$store.state.noLoanModal.show
        },
        set(val) {
          this.$store.state.noLoanModal.show = val
        }
      },
      messages: {
        get() {
          return this.$store.state.noLoanModal.messages
        },
        set(val) {
          this.$store.state.noLoanModal.messages = val
        }
      },
    },

  }
</script>

<style scoped>

</style>
